import React from "react";

class Donate extends React.Component {
  componentDidMount() {
    window.location.replace("https://giving.massgeneral.org/ohif");
  }
  render() {
    return <></>;
  }
}

export default Donate;
